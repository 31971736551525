import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@wix/yoshi-flow-editor';

const Counter = ({
  count = 0,
  countFormatted,
  i18nKey = 'counter.count',
  className,
  showZero = true,
  ariaHidden,
  tabIndex,
}) => {
  const { t } = useTranslation();

  return (
    (count || showZero) && (
      <span aria-hidden={ariaHidden} className={className} tabIndex={tabIndex}>
        {t(i18nKey, { count, numberFormatted: countFormatted })}
      </span>
    )
  );
};

Counter.propTypes = {
  count: PropTypes.number,
  countFormatted: PropTypes.string,
  i18nKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  showZero: PropTypes.bool,
  t: PropTypes.func,
  ariaHidden: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default Counter;
