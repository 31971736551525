// ORIGINAL LIB SOURCE https://github.com/vigetlabs/react-focus-trap
import React from 'react';
import FocalPoint from './focal-point';

const noop = () => {};

class FocusTrap extends React.Component {
  constructor(props, context) {
    super(props, context);
    this._onKeyUp = this._onKeyUp.bind(this);
  }

  render() {
    const {
      active = true,
      className = 'focus-trap',
      children,
      element,
      onExit = noop,
    } = this.props;

    if (!active) {
      return null;
    }

    return (
      <div className={`${className}-wrapper`} onKeyUp={this._onKeyUp}>
        <div
          aria-hidden="true"
          className={`${className}-backdrop`}
          onClick={onExit}
        />
        <FocalPoint className={className} element={element}>
          {children}
        </FocalPoint>
      </div>
    );
  }

  _onKeyUp(event) {
    if (event.key === 'Escape') {
      this.props.onExit();
    }
  }
}

export default FocusTrap;
